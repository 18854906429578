import dynamic from 'next/dynamic';

const Manufacturing = dynamic(() => import('@/icons/maggie/Manufacturing'));
const Construction = dynamic(() => import('@/icons/maggie/Construction'));
const Truck = dynamic(() => import('@/icons/maggie/Truck'));
const Hospitality = dynamic(() => import('@/icons/maggie/Hospitality'));
const Facilities = dynamic(() => import('@/icons/maggie/Facilities'));
const Retail = dynamic(() => import('@/icons/maggie/Retail'));

interface IconsProps {
  icon: string;
  width: number | 16;
  height: number | 16;
}

const Icons = (props: IconsProps) => {
  const { icon, width, height } = props;

  if (icon) {
    switch (icon) {
      case 'manufacturing':
        return <Manufacturing width={width} height={height} />;
      case 'construction':
        return <Construction width={width} height={height} />;
      case 'transport':
        return <Truck width={width} height={height} />;
      case 'hospitality':
        return <Hospitality width={width} height={height} />;
      case 'facilities':
        return <Facilities width={width} height={height} />;
      case 'retail':
        return <Retail width={width} height={height} />;
      default:
        return null;
    }
  }
};

export default Icons;
