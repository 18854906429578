import styles from './Iframe.module.scss';

interface typeIframe {
  src: string;
}

const Iframe = (props: typeIframe) => {
  const { src } = props;

  return (
    <div className={styles.root}>
      <iframe
        src={src}
        width="100%"
        height="300"
        allowFullScreen
        frameBorder={0}
      ></iframe>
    </div>
  );
};

export default Iframe;
